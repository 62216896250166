<template>
  <div id="app">
    <div class="player-container">
      <vue-core-video-player style="width: 90%; height: auto;" :src="videoSource"></vue-core-video-player>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      videoSource: [
        {
          src: 'https://dp-videos.oss-cn-hangzhou.aliyuncs.com/ssbx720.mp4',
          resolution: 720,
        }, {
          src: 'https://dp-videos.oss-cn-hangzhou.aliyuncs.com/ssbx1080.mp4',
          resolution: 1080,
        }, {
          src: 'https://dp-videos.oss-cn-hangzhou.aliyuncs.com/ssbx4000.mp4',
          resolution: 4000
        }
      ]
    }
  }
}
</script>

<style>
html, body {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.player-container {
  width: 100vw;
  height: 100vh;
  background-image: url("https://dp-videos.oss-cn-hangzhou.aliyuncs.com/bg.jpg");
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
